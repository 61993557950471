import axios from "axios";
import { fetchUser } from "./auth.service";
import { auth } from "./index";
import { getKeyFromS3Url } from "utils";
import { SERVER_URL } from "./stripe.service";
import Parse from "./parse.service";

const Event = Parse.Object.extend("Event");
const Booking = Parse.Object.extend("Booking");
const Ticket = Parse.Object.extend("Ticket");
const Coupon = Parse.Object.extend("Coupon");
const Role = Parse.Object.extend("_Role");
const User = Parse.Object.extend("_User");
const Bank = Parse.Object.extend("Bank");

//create a admin role
export function createAdminRole() {
  const roleACL = new Parse.ACL();
  roleACL.setPublicReadAccess(true);
  roleACL.setPublicWriteAccess(true);
  const adminRole = new Parse.Role("Administrator", roleACL);
  adminRole.save();
}

export const addCoupons = async (coupons, eventId, statusValue) => {
  const currentuser = Parse.User.current();
  try {
    // Create an array of promises for saving each coupon
    const savePromises = coupons.map(async (data) => {
      const couponData = {
        code: data?.code,
        type: parseInt(data?.type),
        amount: parseInt(data?.amount),
        isActive: statusValue,
        quantity: parseInt(data?.quantity),
      };

      const coupon = new Coupon();
      // Create a Parse.Object instance for Event with just the object ID
      const eventPointer = Parse.Object.extend("Event");
      const event = new eventPointer();
      event.id = eventId;
      coupon.set("eventId", event);
      coupon.set("organizerId", currentuser);
      coupon.set("used_quantity", 0);

      try {
        // Save the coupon
        const newCoupon = await coupon.save(couponData);
        return newCoupon; // Return the saved coupon
      } catch (error) {
        console.error("Error saving coupon:", error);
        return null; // Return null for failed save
      }
    });

    // Wait for all save operations to complete
    const results = await Promise.all(savePromises);

    // Filter out any null results (failed saves)
    const successfulCoupons = results.filter((result) => result !== null);

    return {
      success: true,
      coupons: successfulCoupons,
    };
  } catch (error) {
    console.error("Error adding coupons:", error);
    return {
      success: false,
      message: "Error adding coupons",
    };
  }
};


export const checkCoupons = async (couponCode, eventId) => {
  try {
    const existingCoupon = await getCouponByCode(couponCode, eventId);
    if (existingCoupon) {
      return { code: 200, message: "Coupon is available" };
    } else {
      return null;
    }
  } catch (error) {
    console.error("checkCoupons error:", error);
    throw new Error({ code: 101, message: error.message });
  }
};

//get all coupons method and return default coupons
export const getCoupon = async () => {
  try {
    const query = new Parse.Query(Coupon);
    const res = await query.find();
    return res;
  } catch (error) {
    console.error("getCoupon error ", error);
  }
};

export const getOrganizerCoupons = async () => {
  try {
    const user = fetchUser();
    const query = new Parse.Query(Coupon);
    query.equalTo("organizerId", user);
    const res = await query.find();
    return res;
  } catch (error) {
    console.error("getOrganizerCoupons error ", error);
    throw error;
  }
};

//method to fetch the coupon details based on eventId
export const getCouponbyEventId = async (id) => {
  try {
    const couponQuery = new Parse.Query(Coupon);
    const newEvent = new Event();
    newEvent.id = id;
    couponQuery.equalTo("eventId", newEvent);
    const res = await couponQuery.find();
    return res;
  } catch (error) {
    console.error("getCouponbyEventId error ", error);
  }
};

//get coupon by couponId
export const getCouponbyId = async (id) => {
  try {
    const query = new Parse.Query(Coupon);
    query.equalTo("objectId", id);
    const res = await query.find();
    return res[0];
  } catch (error) {
    console.error("getCouponbyId Error ", error);
    return error;
  }
};

//update coupon by coupon Id
export const updateCoupon = async (id, newCoupon, eventId) => {
  const getStatus = (status) => {
    if (status === true) {
      return true;
    } else {
      return false;
    }
  };
  try {
    const query = new Parse.Query(Coupon);
    query.equalTo("objectId", id);
    const couponDetails = await query.get(id);

    couponDetails.set("code", newCoupon?.code);
    couponDetails.set("amount", parseInt(newCoupon?.amount));
    couponDetails.set("quantity", parseInt(newCoupon?.quantity));
    couponDetails.set("type", parseInt(newCoupon?.type));
    couponDetails.set("isActive", getStatus(newCoupon?.isActive));
    const res = await couponDetails.save();
    return res;
  } catch (error) {
    console.error("Updating Coupon Data Error ", error);
    return error;
  }
};

//handle the coupon to make active or inactive
export const toggleSwitch = async (id, status) => {
  try {
    const query = new Parse.Query(Coupon);
    query.equalTo("objectId", id);
    const couponDetails = await query.get(id);

    couponDetails.set("isActive", status.get("isActive"));
    await couponDetails.save();
  } catch (error) {
    console.error("toggleSwitch update Error", error);
    return error;
  }
};

//delete coupon by coupon ID
export const deleteCoupon = async (id) => {
  try {
    const query = new Parse.Query(Coupon);
    query.equalTo("objectId", id);
    const couponsDetails = await query.get(id);
    const res = await couponsDetails.destroy();
    return res;
  } catch (error) {
    console.error("Delete Coupon Error ", error);
    throw error;
  }
};

//add event method
export async function addEvent(event) {
  const currentUser = Parse.User.current();
  const myEvent = new Event();

  const eventObj = {
    title: event?.title,
    description: event?.description,
    date: event?.date,
    max_tickets: event?.max_tickets,
    ticket_types: event?.ticket_types,
    address: event?.address,
    cover_photo: event?.cover_photo || "",
    gate_open_time: event?.gate_open_time,
    gate_close_time: event?.gate_close_time,
    sale_start_date: event?.sale_start_date,
    isLive: 0,
  };

  const booking_details = {
    totalTickets: eventObj?.max_tickets,
    max_tickets: eventObj?.max_tickets,
    sold_tickets: 0,
    refund_tickets: 0,
    // total_tickets_amount: 0,
    // refund_tickets_amount: 0,
    // ticket_types_count: {},
  };

  const coupon_details = {};
  try {
    myEvent.set("bookings_count", booking_details);
    myEvent.set("coupons_count", coupon_details);
    myEvent.set("organizerId", currentUser);
    myEvent.set("send_email", false);
    const newEvent = await myEvent.save(eventObj);
    return newEvent;
  } catch (error) {
    console.error("newEvent Error is ", error);
  }
}

//method to update the Event refund amount and refund tickets count
export const updateRefundAmount = async (eventId, bookingId, refundAmount) => {
  try {
    const eventQuery = new Parse.Query(Event);
    const bookingQuery = new Parse.Query(Booking);

    const eventDetails = await eventQuery?.get(eventId);
    const bookingDetails = await bookingQuery?.get(bookingId);

    const booking_count_details = eventDetails?.get("bookings_count");
    const availableTickets = eventDetails?.get("max_tickets");
    const numberOfPeople = bookingDetails?.get("numberOfPeople");

    // const ticketRefundAmount = (booking_count_details?.refund_tickets_amount || 0) + refundAmount;
    const numberOfTicketsRefund = (booking_count_details?.refund_tickets) + numberOfPeople;
    const availableEventTickets = availableTickets + numberOfPeople;
    const updatedBooking = { ...booking_count_details, refund_tickets: numberOfTicketsRefund, max_tickets:availableEventTickets
    };

    eventDetails.set("max_tickets",availableEventTickets);
    eventDetails.set("bookings_count", updatedBooking);
    const response = await eventDetails.save();
    return response;
  } catch (error) {
    console.error("updateRefundAmount error ", error);
  }
};

//fetching all events and automatically isLive can set to false if the event is outdated
export const getEvents = async () => {
  try {
    const query = new Parse.Query(Event);
    query.include("organizerId");
    const res = await query.find();
    const currentDate = new Date();

    for (let event of res) {
      const eventDate = event.get("date");
      const isLive = event.get("isLive");
      if (eventDate < currentDate && isLive === 1) {
        event.set("isLive", 2);
        // Save the updated event individually
        await event.save();
      }
    }
    return res;
  } catch (error) {
    console.error("Error fetching or updating events:", error);
    throw error;
  }
};

//fetch the only live Events
export const getLiveEvents = async () => {
  try {
    const query = new Parse.Query(Event);
    query.equalTo("isLive", 1);
    const res = await query.find();
    getEvents();
    return res;
  } catch (error) {
    console.error("Error fetching or updating events:", error);
    throw error;
  }
};

//get the Organizerevents based on Organizer Id
export const getEventByUserId = async () => {
  const res = fetchUser();
  const userId = res.id;
  const user = Parse.User.createWithoutData(userId);
  const query = new Parse.Query(Event);
  query.equalTo("organizerId", user);
  try {
    const data = await query.find();
    return data;
  } catch (error) {
    console.error("user error ", error);
  }
};

export function getEventDetail(id) {
  const query = new Parse.Query(Event);
  query.limit(1);
  query.equalTo("objectId", id);
  query.include("organizerId");
  return query
    .find()
    .then((results) => {
      if (results.length != 0) {
        return results[0];
      } else {
        return null;
      }
    })
    .catch((error) => {
      console.error("getting single event by id error ", error);
    });
}

//event update method
export async function updateEvent(event, id) {
  try {
    const query = new Parse.Query(Event);
    query.limit(1);
    query.equalTo("objectId", id);
    const eventdetails = await query.get(id);

    let updatedBookingCounts = {
      ...eventdetails?.get("bookings_count"),
      totalTickets: event?.max_tickets,
      max_tickets: event?.max_tickets,
    };

    eventdetails.set("title", event?.title);
    // eventdetails.set("ticket_types", event?.ticket_types);
    eventdetails.set("ticket_types", event?.ticket_types);
    eventdetails.set("description", event?.description);
    eventdetails.set("cover_photo", event?.cover_photo);
    eventdetails.set("max_tickets", event?.max_tickets);
    eventdetails.set("address", event?.address);
    eventdetails.set("date", event?.date);
    eventdetails.set("bookings_count", updatedBookingCounts);
    eventdetails.set("gate_open_time", event?.gate_open_time);
    eventdetails.set("gate_close_time", event?.gate_close_time);
    eventdetails.set("sale_start_date", event?.sale_start_date);
    const res = await eventdetails.save();
    return res;
  } catch (error) {
    console.error("Ticket Upadate Error is ", error);
  }
}

//make event live method
export async function postEventLive(id) {
  try {
    const query = new Parse.Query(Event);
    query?.equalTo("objectId", id);
    const eventDetails = await query?.get(id);
    eventDetails.set("isLive", 1);
    const eventData = await eventDetails?.save();
    //method to making all coupons live when event go live
    const couponData = await getCouponbyEventId(id);
    const couponPromises = couponData.map(async (item) => {
      if (item?.get("isActive")) {
        return true;
      } else {
        item.set("isActive", true);
        return item.save();
      }
    });

    //all coupons are saving
    await Promise.all(couponPromises);
    return eventData;
  } catch (error) {
    console.error("Event Golive Error ", error);
  }
}

//method to make event live(1) or draft (0) based on EventId
export const postEventLiveOrDraft = async (eventId, eventStatusValue) => {
  try {
    const query = new Parse.Query(Event);
    const event = await query?.get(eventId);
    event.set("isLive", eventStatusValue);
    const eventData = await event?.save();
    return eventData;
  } catch (error) {
    console.error("Event Golive Error ", error);
    throw new Error(error);
  }
};

//delete event method
export async function deleteEventbyId(id) {
  const query = new Parse.Query(Event);
  query.equalTo("objectId", id);
  try {
    const eventdetais = await query.get(id);
    const imgUrl = eventdetais?.get("cover_photo");
    const objectKey = await getKeyFromS3Url(imgUrl);
    const checkImgexist = await checkObject(objectKey);
    if (checkImgexist?.exists) {
      const deleteObject = await deleteObjectFromS3(objectKey);
    }
    const res = await eventdetais.destroy();
    return res;
  } catch (error) {
    console.error("Deleting Event Error ", error);
  }
}

export const updateCouponQuantity = async (couponCode, eventId) => {
  try {
    const query = new Parse.Query(Coupon);
    const event = new Event();
    event.id = eventId;
    query.equalTo("code", couponCode);
    query.equalTo("eventId", event);

    const res = await query.find();
    const couponQuery = res[0];

    if (couponQuery) {
      const quantity = couponQuery?.get("quantity");
      const isActive = couponQuery?.get("isActive");
      const usedQuantity = couponQuery?.get("used_quantity") ?? 0 ;

      if (quantity > 0 && isActive) {
        let updatedQuantity = quantity - 1;
        // let usedQuantity = (couponQuery?.get("used_quantity") ?? 0) + 1;
        let newUsedQuantity = usedQuantity + 1;

        couponQuery?.set("quantity", updatedQuantity);
        couponQuery?.set("used_quantity", newUsedQuantity);
        if (updatedQuantity === 0) {
          couponQuery?.set("isActive", false);
        }
        const res = await couponQuery?.save();
        return res;
      } else {
        console.log("quantity is nagative  or zero not active ",quantity);
      }
    }
  } catch (error) {
    console.error("updateCouponQuantity error ", error);
    throw error;
  }
};

//update event Tickets count and types of Tickets count and coupons count
export async function eventTicketUpdate(bookingData) {
  if (!bookingData) return;
    const { eventId, totalTickets, totalAmount, ticketSoldOut, couponCode } = bookingData;
    
    const maxTicketTotal = totalTickets;

    try {
      // Fetch the event object by eventId
      const query = new Parse.Query(Event);
      query.equalTo("objectId", eventId);
      const eventObject = await query.first();

      if (!eventObject) return;
        const eventBookingCount = eventObject?.get("bookings_count");
        const eventCouponCount = eventObject?.get("coupons_count");

         
        const updatedBookingsCount = {
          ...eventBookingCount,
          max_tickets: maxTicketTotal,
          sold_tickets: (eventBookingCount?.sold_tickets || 0) + ticketSoldOut,
        };

        eventObject.set("bookings_count", updatedBookingsCount);// Update booking count
        eventObject.set("max_tickets", maxTicketTotal);//updating tickets count

        //updating coupons count
        if (couponCode) {
          eventCouponCount[couponCode] = (eventCouponCount[couponCode] || 0) + 1;
        }

        //updating coupon quantity
        await updateCouponQuantity(couponCode, eventId);

        const res = await eventObject.save();
        return res.toJSON();
     
    } catch (error) {
      console.error("eventTicketUpdate error ", error);
      throw error;
    }
  
}

export function pdfGenerate() {
  return Parse.Cloud.run("generateTicket")
    .then((res) => res)
    .catch((err) => err);
}

export function createBooking(eventId, amount, coupon, totalPeople) {
  const booking = new Booking();

  booking.set("userId", auth.fetchUser());
  booking.set("status", "open");
  booking.set("amount", amount);
  booking.set("coupon", coupon);
  booking.set("checkedIn", false);
  booking.set("numberOfPeople",totalPeople);
  booking.set("refundStatus", { status: false, payment_intent: "" });

  const event = new Event();
  event.id = eventId;
  booking.set("eventId", event);

  return booking.save().then(
    (booking) => {
      return booking;
    },
    (error) => {
      return error.message;
    }
  );
}

//method to update the booking payment status and paymentIntentId
export const updatePaymentIntent = async (bookingId, paymentIntentId) => {
  try {
    const booking = new Parse.Query(Booking);
    const bookingQuery = await booking.get(bookingId);
    const obj = { status: false, payment_intent: paymentIntentId };
    bookingQuery.set("refundStatus", obj);
    await bookingQuery.save();
  } catch (error) {
    console.error("updatePaymentIntent error ", error);
  }
};

export function updateBookingPayment(bookingId) {
  return getBookingById(bookingId).then((booking) => {
    booking.set("status", "complete");
    return booking.save();
  });
}

export function updateBookingTicketLink(bookingId, link) {
  return getBookingById(bookingId).then((booking) => {
    booking.set("ticket_url", link);
    return booking.save();
  });
}

export async function getBookings() {
  try {
    const query = new Parse.Query(Booking);
    query.equalTo("userId", auth.fetchUser());
    query.include("eventId");
    query.include("userId");
    query.addDescending("createdAt");
    const results = await query.find();

    return results.length > 0 ? results : null;
  } catch (error) {
    console.error("Error fetching bookings: ", error);
    throw error;
  }
}

//method to get the bookings details based on role
export const getBookingsAmount = async (role) => {
  try {
    const query = new Parse.Query(Booking);
    query.equalTo("status", "complete");

    let bookings;
    // Admin can see all organisers events bookings
    if (role === "admin") {
      bookings = await query.find();
    }
    //organiser can see his bookings
    if (role === "organizer") {
      const currentUser = await Parse.User.current();
      const event = new Parse.Query(Event);
      event.equalTo("organizerId", currentUser);
      query.matchesQuery("eventId", event);
      bookings = await query.find();
    }

    let totalBookings = 0;
    let bookingsAmount = 0;
    let refundAmount = 0;
    let totalRefunds = 0;

    // Analyze bookings for refunds
    bookings?.forEach((booking) => {
      const amount = booking?.get("amount");
      const refundStatus = booking?.get("refundStatus")?.status;
      const totalBookingPeople = booking?.get("numberOfPeople") ?? 0;

      if (refundStatus) {
        // totalRefunds += 1;
        refundAmount += amount;
        totalRefunds += totalBookingPeople;
      } else {
        bookingsAmount += amount;
        totalBookings += totalBookingPeople;
      }
      
    });

    // Returning the total amount and total bookings (non-refunded)
    return {
      totalBookings,
      bookingsAmount,
      refundAmount,
      totalRefunds,
    };
  } catch (error) {
    console.error("Error calculating total amount: ", error);
    throw error;
  }
};

export const getAllBookingsByEventId = async(eventId)=>{
  try {
    //creating event pointer
    const eventPointer = new Parse.Object("Event");
    eventPointer.id = eventId;

    const bookingQuery = new Parse.Query("Booking");
    bookingQuery.equalTo("eventId",eventPointer);

    const bookingsArray = await bookingQuery.find();

    let totalTickets = 0;
    let totalAmount = 0;
    
    bookingsArray?.map((booking)=>{
      if(booking?.get("status") === "complete" && !(booking?.get("refundStatus")?.status)){
        totalTickets+=(booking?.get("numberOfPeople") ?? 0);
        totalAmount += (booking?.get("amount") ?? 0);
      }
    })
    return {totalTickets,totalAmount}
  } catch (error) {
    console.error("getAllBookingsByEventId error ",error)
  }
}

export const getEventGrossAmount = async(eventId)=>{
  try {
   // Create a pointer to the event object
    const eventPointer = new Parse.Object("Event");
    eventPointer.id = eventId; 

    const bookingQuery = new Parse.Query(Booking);
    bookingQuery.equalTo("eventId",eventPointer);
    const bookingsArray = await bookingQuery.find();

    // Calculate gross amount by reducing the valid bookings
    const grossAmount = bookingsArray?.reduce((accumualtor,item)=>{
      const validBooking = item?.get("status") === "complete";
      if(validBooking) return accumualtor+(item?.get("amount") ?? 0)
        return accumualtor;
    },0)
    return grossAmount;
  } catch (error) {
    console.error("getEventGrossAmount Error ",error)
    throw error;
  }
}

export function getBookingById(id) {
  const query = new Parse.Query(Booking);
  query.equalTo("objectId", id);
  query.limit(1);
  query.include("eventId");
  return query
    .find()
    .then((bookings) => {
      if (bookings.length != 0) {
        return bookings[0];
      }
      return null;
    })
    .catch((err) => err);
}

//get all Event bookings of organizer or admin based on user ID
export const getOrganizerAllBookings = async (organizerID) => {
  try {
    const currentUser = await Parse.User.current();
    //creating Event POinter
    const event = new Parse.Query(Event);
    event.equalTo("organizerId", currentUser);

    const booking = new Parse.Query(Booking);
    booking.matchesQuery("eventId", event);
    booking.include("userId");

    const result = await booking.find();
    return result;
  } catch (error) {
    console.error("getOrganizerAllBookings error ", error);
  }
};

export const getAllBookings = async () => {
  try {
    const booking = new Parse.Query(Booking);
    booking.include("userId");
    booking.include("eventId");
    const result = await booking.find();

    return result;
  } catch (error) {
    console.error("getAllBookings error ", error);
  }
};

//method to verify the Ticket
export const verifyTicket = async (ticketId) => {
  try {
    const booking = new Parse.Query(Booking);
    booking.equalTo("status", "complete");
    const bookingResponse = await booking.get(ticketId);

    if (bookingResponse) {
      return bookingResponse;
    }
    return null;
  } catch (error) {
    console.error("verifyTicket error  ", error);
    throw new Error(error);
  }
};

//method to allow the checkIn
export const verifyCheckIn = async (ticketId) => {
  try {
    const booking = new Parse.Query(Booking);
    const bookingResponse = await booking.get(ticketId);

    if (bookingResponse) {
      bookingResponse.set("checkedIn", true);
      await bookingResponse.save();
      return bookingResponse;
    }
    return null;
  } catch (error) {
    console.error("verifyTicket error  ", error);
    throw new Error(error);
  }
};

export async function createTicket(bookingId) {
  const booking = await getBookingById(bookingId);
  const event = booking?.get("eventId");
  const address = event?.get("address");
  const venue = address?.split(",")[0];
  const splitAddress = address?.split(" ");
  const city = splitAddress[splitAddress.length - 1];
  const user = auth.fetchUser();

  const params = {
    ticketId: booking.id,
    title: event?.get("title"),
    date: event?.get("date"),
    time: "19:00",
    city: city,
    venue: venue,
    address: address,
    price: booking?.get("amount"),
    email: user?.get("email"),
  };

  return await Parse.Cloud.run("createTicket", params);
}

export function getCouponByCode(_code, eventId) {
  const query = new Parse.Query(Coupon);
  const event = new Event();
  event.id = eventId;
  query.equalTo("code", _code);
  query.equalTo("eventId", event);
  query.limit(1);
  return query
    .find()
    .then((results) => {
      if (results.length !== 0) {
        let coupon = results[0];
        return coupon;
        // let isActive = coupon?.get("isActive");
        // if (isActive) {
        //   return results[0];
        // } else {
        //   return null;
        // }
      }
      return null;
    })
    .catch((err) => {
      console.error("getCouponByCode Error ",err);
    });
}

export function applyCoupon(_code) {
  const query = new Parse.Query(Coupon);
  query.equalTo("code", _code);
  query.limit(1);
  return query
    .find()
    .then((results) => {
      if (results.length != 0) return results[0];
      return null;
    })
    .catch((err) => {
      console.error("applyCoupon error ",err);
    });
}

//get the current user role name
export const getCurrentUserRoles = async () => {
  const currentUser = Parse.User.current();
  if (!currentUser) {
    return null;
  }
  const query = new Parse.Query(Role);
  query.equalTo("users", currentUser);
  const userRoles = await query.find();
  const res = userRoles.map((role) => role.get("name"));

  return res[0];
};

//get the all organizer events base on organizer Id
export const organizerEvents = async () => {
  
  try {
    const currentUser = await  auth.fetchUser();
    const query = new Parse.Query(Event);
    query.equalTo("organizerId", currentUser);

    const res = await query.find();
    return res;
  } catch (error) {
    console.error("organizerEvents error ", error);
  }
};

//get the user information based on userId
export const getUserDetails = async (userId) => {
  try {
    const query = new Parse.Query(Parse.User);
    query.equalTo("objectId", userId);
    const user = await query.first();

    if (!user) {
      return null;
    }
    return user;
  } catch (error) {
    throw error;
  }
};

//method to check the email is exist in data base or not for creatinf new user
export const getUserEmail = async (email) => {
  const existingUser = await Parse.Cloud.run("getUserByEmail", { email });
  return existingUser;
};

//method to get fetch the event organization information for tono mail
export const fetchOragnizerInformation = async (eventId) => {
  const res = await getEventDetail(eventId);
  const singleEvent = res;
  let event_information = {
    event_name: singleEvent?.get("title"),
    event_address: singleEvent?.get("address"),
    event_start_date: singleEvent?.get("date"),
    total_tickets_count: singleEvent?.get("bookings_count")?.sold_tickets,
    audient_count: singleEvent?.get("bookings_count")?.sold_tickets,
    // gross_revenue: singleEvent?.get("bookings_count")?.total_tickets_amount || 0,
  };

  const userdetails = singleEvent?.get("organizerId");

  let organizer_information = {
    organizer_name: userdetails?.get("username"),
    organizer_phone: userdetails?.get("profileId")?.get("phone"),
    organizer_country: userdetails?.get("profileId")?.get("country"),
    organizer_email: userdetails?.get("email"),
    organizer_address: userdetails?.get("profileId")?.get("address"),
  };

  let event_organizer_detiails = {
    ...organizer_information,
    ...event_information,
  };

  return event_organizer_detiails;
};

//method to update the tono pdf send status
export const sendEmailStatus = async (id) => {
  try {
    const query = new Parse.Query(Event);
    const event = await query.get(id);

    event.set("send_email", true);
    const res = await event.save();
    return res;
  } catch (error) {
    console.error("sendEmailStatus event error ", error);
    return error;
  }
};

//method to add the organizer bank details
export const addBankDetails = async (bankdata) => {
  // delete bankdata.confirmAccountNumber;
  const currentUser = Parse.User.current();
  try {
    const bank = new Bank();
    bank.set("organizerId", currentUser);
    const res = await bank.save(bankdata);
    return res;
  } catch (error) {
    console.error("addBankDetails Error", error);
    throw error;
  }
};

//method to fetch the user bank details based on userId
export const getBankDetails = async () => {
  try {
    let currentUser = fetchUser();
    const BankQuery = new Parse.Query(Bank);
    BankQuery.equalTo("organizerId", currentUser);
    const res = await BankQuery?.find();
    return res[0];
  } catch (error) {
    console.error("getBankDetails Error ", error);
    throw error;
  }
};

//method to update the bank acount details
export const updateBankDetails = async (BankDetails, id) => {
  try {
    let currentUser = fetchUser();
    const BankQuery = new Parse.Query(Bank);
    BankQuery.equalTo("organizerId", currentUser);

    const bankdetails = await BankQuery.get(id);
    bankdetails.set("accountName", BankDetails?.accountName);
    bankdetails.set("accountNumber", Number(BankDetails?.accountNumber ?? 0));
    bankdetails.set(
      "confirmAccountNumber",
      Number(BankDetails?.confirmAccountNumber ?? 0)
    );
    bankdetails.set("accountType", BankDetails?.accountType);
    bankdetails.set("location", BankDetails?.location);
    bankdetails.set("code", BankDetails?.code);

    const res = await bankdetails.save();
    return res;
  } catch (error) {
    console.error("updateBankDetails Error ", error);
    throw error;
  }
};

//method to delete the image from aws s3 and in event
export const deleteObjectFromS3 = async (filename, eventId) => {
  try {
    const response = await Parse.Cloud.run("deleteCoverPhotoObject", {
      key: filename,
    });
    console.log("deleteObjectFromS3 Response", response);
    if (response?.success) {
      const eventQuery = new Parse.Query(Event);
      const event = await eventQuery?.get(eventId);
      if (event) {
        event.set("cover_photo", "");
        await event.save();
      } else {
        console.error("Event not found ",event);
      }
    }

    return response;
  } catch (error) {
    console.error("Error in deleteObjectFromS3: ", error);
  }
};

export const checkObject = async (filename) => {
  if (filename) {
    const response = await Parse.Cloud.run("checkCoverPhotoObject", {
      key: filename,
    });
    return response;
  } else {
    return;
  }
};

//method to add the image in aws storage
export const addImagetoS3 = async (file) => {
  const formData = new FormData();
  formData.append("file", file);

  let user = Parse.User.current();
  let token = user.get("sessionToken");

  try {
    const response = await axios.post(`${SERVER_URL}/uploadImage`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "parse-session-token": token,
      },
    });
    console.log("addImagetoS3 File uploaded successfully:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error uploading file:", error);
    throw error;
  }
};