import React, { Suspense, lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Header, Footer } from "./components/shared";
import { ContextProvider } from "MyContext";
import RequiredContext from "RequiredContext";
import Spinner from "components/shared/Spinner";
import "./App.css";
import "react-datepicker/dist/react-datepicker.css";

// Lazy load views
const Home = lazy(() => import("./views/Home/Home"));
const EventDetail = lazy(() => import("./views/EventDetail"));
const Checkout = lazy(() => import("./views/Checkout"));
const About = lazy(() => import("./views/pages/About"));
const Terms = lazy(() => import("./views/pages/Terms"));
const Login = lazy(() => import("./views/Auth/Login"));
const Signup = lazy(() => import("./views/Auth/Signup"));
const Profile = lazy(() => import("./views/Profile/Index"));
const Tickets = lazy(() => import("./views/Tickets"));
const Return = lazy(() => import("./views/Return"));
const Admin = lazy(() => import("./views/Admin"));
const NotFound = lazy(() => import("views/pages/NotFound"));
const Stepper = lazy(() => import("views/Admin/Stepper/Index"));
const Dashboard = lazy(() => import("views/Dashboard/Index"));
const PasswordReset = lazy(() => import("./views/Auth/PasswordReset"));
const TonoForm = lazy(() => import("./views/Tono/index"));

function App() {
  return (
    <BrowserRouter>
      <div className="container-fluid">
        <ContextProvider>
          <Header />
          <Suspense fallback={<Spinner />}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/login" element={<Login />} />
              <Route path="/password-reset" element={<PasswordReset />} />
              <Route path="/about" element={<About />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/events/addEvent" element={<Stepper />} />
              <Route path="/eventEdit/:id" element={<Stepper />} />
              <Route path="/event/:id" element={<EventDetail />} />
              <Route path="/checkout" element={<Checkout />} />
              <Route path="/return" element={<Return />} />
              {/* <Route path="/tonoForm" element={<TonoForm/>}/> */}
              <Route
                path="/events"
                element={
                  <RequiredContext>
                    <Admin />
                  </RequiredContext>
                }
              />
              <Route
                path="/tickets"
                element={
                  <RequiredContext>
                    <Tickets />
                  </RequiredContext>
                }
              />
              <Route
                path="/profile"
                element={
                  <RequiredContext>
                    <Profile />
                  </RequiredContext>
                }
              />
              <Route
                path="/dashboard"
                element={
                  <RequiredContext>
                    <Dashboard />
                  </RequiredContext>
                }
              />
              <Route
                path="/tono/:id"
                element={
                  <RequiredContext>
                    <TonoForm />
                  </RequiredContext>
                }
              />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Suspense>
          <Footer />
        </ContextProvider>
      </div>
    </BrowserRouter>
  );
}

export default App;