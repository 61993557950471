import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "services";

export const MyContext = createContext();

export const ContextProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);
  const [bookingdata, setBookingdata] = useState({});
  const navigate = useNavigate();

  const login = (name) => {
    setUserData(name);
  };
  const logout = () => {
    setUserData(null);
    navigate("/", { replace: true });
  };

  const handleBooking = (data) => {
    setBookingdata(data);
  };

  const handleUser = (userDetails)=>{
    setUserData(userDetails)
  }
  
  const fetchUser = async()=>{
    try {
      const res = await auth.fetchUser();
      setUserData(res)
    } catch (error) {
      console.error("fetchUser Error :",error)
    }
  }
  useEffect(()=>{
    fetchUser()
  },[])

  return (
    < MyContext.Provider value={{ userData, login, logout, handleUser, handleBooking, bookingdata }} >
      <>{children}</>
    </ MyContext.Provider >
  );
};

export const useMyContext = () => useContext(MyContext);