import React from 'react'



export const TicketCheckIn = () => {
    //method to vailidate the required input fields on entering input field
const ValidateField = (fieldName, value) => {
    switch (fieldName) {
       case "ticketId":
        return validateTicketId(value);
        default:
            return "";
    }
};

//validate the ticketId
const validateTicketId= (value)=>{
    if (!value?.trim()) {
        return "Ticket Id is Required"
      } else {
        return "";
      }
}

//validate TicketCheckIn Form
const TicketCheckInForm = (formDate) => {
    const newErrors = {
    ticketId:validateTicketId(formDate?.ticketId)
    };

    if ( newErrors?.ticketId ) {
      return { err: newErrors };
    } else {
      return { err: "" };
    }
  };
  return (
    {ValidateField,TicketCheckInForm}
  )
}

export default TicketCheckIn
