import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { auth, api } from "services";
import { useTranslation } from "react-i18next";
import logo from "../../Assets/images/logo.svg";
import Parse from "parse";
import CheckTicket from "components/Modals/CheckTicket";
import TicketCheckIn from "utils/validation/TicketCheckIn";

const Header = () => {
  const [ticketDetails, setTicketDetails] = useState({ ticketId: "", isVerify: false, isCheckIn: false, counter: 3, showCamera: false });
  const [countryLanguage, setCountryLanguage] = useState();
  const [userRole, setUserRole] = useState(null);
  const [error, setError] = useState(null);

  const { t, i18n } = useTranslation(["common", "home"]);
  // const userData = auth.fetchUser();
  const navigate = useNavigate();
  const closeButtonRef = useRef(null);
  const { ValidateField, TicketCheckInForm } = TicketCheckIn();

  const user = auth.fetchUser();

  //intially it will set the localization language as norway
  useEffect(() => {
    let currentLanguage = i18n.language;
    setCountryLanguage(currentLanguage);
    getRole();
  }, []);

  //method to turnOff the Camera
  const handleTurnOFFCamera = () => {
    // setShowCamera(false)
    setTicketDetails((prev) => ({ ...prev, showCamera: false }))
  }

  //handle successfull scan
  const handleScan = (data) => {
    if (data) {
      const qrData = data?.[0]?.rawValue;
      setTicketDetails((prev) => ({ ...prev, ticketId: qrData, showCamera: false }));
    }
  };

  //method to toggle the camera ON or OFF
  const toggleCamera = ()=>{
    setTicketDetails((prev)=>({...prev,showCamera:!prev.showCamera}))
  }

  //handle Scanner Data Errors
  const handleError = (err) => {
    console.error("Scanner error:", err);
  };

  //get current logged in user role
  const getRole = async () => {
    const response = await api.getCurrentUserRoles();
    setUserRole(response);
  };

  //handle to show the language
  const showLanguage = () => {
    if (countryLanguage === "en") {
      return "EN";
    } else if (countryLanguage === "nw") {
      return "NO";
    }
  };

  //method for logout user
  const Logout = async () => {
    await Parse.User.logOut();
    localStorage.clear();
    sessionStorage.clear();
    setUserRole("");
    navigate("/");
  };

  //handle change browser language
  const handleLanguage = (language) => {
    i18n.changeLanguage(language);
    setCountryLanguage(language);
    localStorage.setItem("language", JSON.stringify(language));
  };

  //method to check the ticket is valid or not
  const handleTicket = async () => {
    const { err } = TicketCheckInForm(ticketDetails);
    if (err) {
      setError(err?.ticketId);
      return;
    }
    try {
      const res = await api.verifyTicket(ticketDetails?.ticketId);
      if (res) {
        setTicketDetails((prev) => ({ ...prev, isVerify: true, isCheckIn: res?.get("checkedIn") }));
        if (res?.get("checkedIn")) {
          setError("Valid Ticket.. Already Checked-In");

          // if ticket is already checked then counter decrement time interval will call
          const myInterval = setInterval(() => {
            setTicketDetails((prev) => ({ ...prev, counter: prev.counter - 1 }));
          }, 1000);
          setTicketDetails((prev) => ({ ...prev, intervalId: myInterval }));

          //after 3 seconds it wil clear the counter decrement time interval
          setTimeout(() => {
            if (closeButtonRef.current) {
              closeButtonRef.current.click();
            }
            handleCloseModel();
            clearInterval(myInterval);
          }, 3000);

        } else {
          setError("Valid Ticket.. Kindly proceed to Check-In");
        }
      }
    } catch (error) {
      console.error("handleTicket error ", error);
      setError("Please Enter valid Ticket ID");
    }
  };

  //method to allow the ticket checkIn
  const handleCheckIn = async () => {
    try {
      const bookingResponse = await api.verifyCheckIn(ticketDetails?.ticketId);
      if (bookingResponse?.get("checkedIn")) {
        setTicketDetails((prev) => ({ ...prev, isCheckIn: true }));
        setError("Successfully CheckedIn");

        //it ticket checkin is done successfully then counter decrement time interval wil call
        const myInterval = setInterval(() => {
          setTicketDetails((prev) => ({ ...prev, counter: prev.counter - 1 }));
        }, 1000);
        setTicketDetails((prev) => ({ ...prev, intervalId: myInterval }));


        //it will call after 3seconds and it will clear the counter time decrement interval
        setTimeout(() => {
          if (closeButtonRef.current) {
            closeButtonRef.current.click();
          }
          handleCloseModel();
          clearInterval(myInterval);
        }, 3000);
      }
    } catch (error) {
      console.error("handleCheckIn error ", error);
    }
  };

  //method to update the ticketDetails state
  const handleTicketDetails = (e) => {
    const { name, value } = e.target;
    const err = ValidateField(name, value);
    setError(err);
    setTicketDetails((prev) => ({ ...prev, [name]: value }));
  };

  //method to close the modal
  const handleCloseModel = () => {
    setTicketDetails((prev) => ({ ...prev, ticketId: "",intervalId:"", isVerify: false, isCheckIn: false, counter: 3,showCamera:false }));
    //it wil cleat the interval if it has been already set
    if (ticketDetails?.inervalId) {
      clearInterval(ticketDetails?.intervalId)
    }
    setError("");
  };

  //handle user not logged In
  const nonLoggedIn = () => {
    return (
      <Link to="/login" className="text-reset text-decoration-none fs-5">
        <div className="mt-1">
          <FontAwesomeIcon icon={faUser} className="mx-0" />
          <p className="d-inline fs-4 px-2">{t("login")}</p>
        </div>
      </Link>
    );
  };
  
  //handle user already logged In
  const loggedIn = () => {
    getRole();
    return (
      <div className="d-md-flex">
        <div className="dropdown">
          <a className="nav-link dropdown-toggle fs-5 fw-bold" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown">
            {user?.get("username")}
          </a>
          <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuLink" >
            {userRole === "user" && (
              <li className="cursor">
                <p className="dropdown-item m-0" onClick={() => navigate("/tickets")} >
                  {t("dropdown.myTickets")}
                </p>
              </li>
            )}
            <li>
              {userRole !== "user" && (
                <p className="dropdown-item m-0" onClick={() => navigate("/events")} role="button" >
                  {t("dropdown.event")}
                </p>
              )}
            </li>
            <li> <hr className="dropdown-divider" /> </li>
            <li>
              <p className="dropdown-item m-0" onClick={() => navigate("/profile")} role="button">
                {t("dropdown.profile")}
              </p>
            </li>
            <li>
              <a className="dropdown-item" href=" " role="button" onClick={Logout} >
                {t("dropdown.logout")}
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  const renderLoginLinks = () => {
    return user ? loggedIn() : nonLoggedIn();
  };

  // CheckTicket modal Props
  const checkTicketProps = { ticketDetails, error, closeButtonRef, toggleCamera, handleTurnOFFCamera, handleError, handleScan, handleTicketDetails, handleTicket, handleCheckIn, handleCloseModel }

  return (
    <nav className="navbar navbar-expand-lg mb-md-3">
      <div className="container">
        <Link className="navbar-brand" to={userRole === "admin" || userRole === "organizer" ? "/dashboard" : "/"}>
          <img src={logo} alt="logo" />
        </Link>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav w-100">
            <li className="nav-item me-auto">
              <Link className="nav-link active fs-5" to="/about">
                {t("header_title")}
              </Link>
            </li>
            {userRole !== "user" &&
              (userRole === "organizer" || userRole === "admin") && (
                <li>
                  <button type="button" className="btn fw-bold mt-2 border" data-bs-toggle="modal" data-bs-target="#checkTicket">
                    Check Ticket
                  </button>
                </li>
              )}
            <li className="nav-item">
              <div className="dropdown">
                <button className="btn dropdown-toggle fw-bold fs-5 py-1 mt-1 px-md-2 px-0" type="button" data-bs-toggle="dropdown">
                  {showLanguage()}
                </button>
                <ul className="dropdown-menu">
                  <li className="cursor">
                    <a className="dropdown-item" onClick={() => handleLanguage("en")}> EN </a>
                  </li>
                  <li>
                    <a className="dropdown-item cursor" onClick={() => handleLanguage("nw")} > NO </a>
                  </li>
                </ul>
              </div>
            </li>
            <li className="nav-item dropdown">{renderLoginLinks()}</li>
          </ul>
        </div>
        
      </div>
     {/* Check Ticket Modal */}
      <CheckTicket {...checkTicketProps} />
    </nav>
  );
};
export default Header;